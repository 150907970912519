<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      @batchDeleteHandler="batchDelete"
      @addHandler="saveTripComplain"
      :loading="loading"
      :showAdd="false"
      :showDelete="false"
    >
      <template v-slot:search>
        <el-form-item label="车牌号:">
          <el-input
            v-model.trim="search.carNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="当值司机:">
          <el-input
            v-model.trim="search.driverName"
            placeholder="请输入当值司机"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="carNum" label="被投诉车辆"></el-table-column>
        <el-table-column prop="driverName" label="当值司机"></el-table-column>
        <el-table-column prop="userName" label="乘客姓名"></el-table-column>
        <el-table-column prop="userMobile" label="投诉人电话"></el-table-column>
        <el-table-column prop="typeName" label="投诉类型"></el-table-column>
        <el-table-column prop="content" label="描述"></el-table-column>
        <el-table-column prop="createTime" label="发生时间">
          <template scope="s">
            {{dateFormatType(s.row.createTime)}}
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TableCompnent from "@/components/table/TablesComponents";
import TripComplainApi from "@/api/TripComplainApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "TripComplainList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        carNum: "",
        driverName: "",
      },
      page: {},
      loading: true,
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({pageSize:20,pageNum:1})
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripComplainApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripComplain() {
      navTo(this, "/saveTripComplain");
    },
    queryById(id) {
      TripComplainApi.queryTripComplainById(id).then((resp) => {
        if (resp.code === '200') {
          queryParam(this, "saveTripComplain", resp.data);
        }
      });
    },
    deleteById(id) {
      TripComplainApi.deleteTripComplain(id).then((resp) => {
        if (resp.code === '200') {
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    batchDelete(params) {
      TripComplainApi.batchDeleteTripComplain(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    dateFormatType(val) {
      if (!val) {
        return "";
      }
      var date = new Date(val);
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // js从0开始取
      var date1 = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      if ((month + "").length === 1) {
        month = "0" + month;
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1;
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour;
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes;
      }
      if ((second + "").length === 1) {
        second = "0" + second;
      }

      return (
        year +
        "-" +
        month +
        "-" +
        date1 +
        '  ' +
        hour +
        ":" +
        minutes +
        ":" +
        second
      );
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->