import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const TripComplainApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripComplainlist`,data),//这里必须写清楚接口的注释说明
    saveTripComplain: (data) => AppHttpKit.postJSON(`/v1/saveTripComplain`,data),
    queryTripComplainById: (data) => AppHttpKit.postJSON(`/v1/queryTripComplainById/`,data),
    updateTripComplain: (data) => AppHttpKit.postJSON(`/v1/updateTripComplain/`,data),
    deleteTripComplain: (data) => AppHttpKit.postJSON(`/v1/deleteTripComplain/`,data),
    batchDeleteTripComplain:(data) => AppHttpKit.postJSON(`/v1/batchDeleteTripComplain/`,data),
}
export default TripComplainApi;
